:root {
    /* colors */
    --color--primary: #e8e000;
    --color--secondary: #6f6f6f;
    --color--third: #f59338;
    --color--light: #fff;
    --color--dark: #f7f7f7;
    --color--font-base: #6f6f6f;

    /* typography */
    --font--base-family: Ropa;
    --font--headline-family: Ropa;
    --font--base-size: 20px;

    /* spacing */
    --spacing--sm: 1.5rem;
    --spacing--md: 3rem;
    --spacing--lg: 6rem;
    --spacing--xl: 8rem;

    /* radius */
    --radius--sm: 0.35rem;
    --radius--md: 1rem;
    --radius--lg: 2rem;

    /* shadow */
    --shadow--sm: 0 0px 10px rgba(0, 0, 0, 0.1);
    --shadow--md: 0 0px 16px rgba(0, 0, 0, 0.175);
    --shadow--lg: 0 0px 30px rgba(0, 0, 0, 0.2);

    /* easing */
    --easing--in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --easing--in: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --easing--out: cubic-bezier(0.075, 0.82, 0.165, 1);

    /* text-size*/
    /* xs */
    --text--xs: 0.75rem;

    --text--xs-height: 1rem;

    /* sm */
    --headline--sm: 2rem;

    /* spacing */
    --spacing--xs: 0.75rem;

    /* base */
    @media (--media--sm) {
        --text--base: 1rem;
    }
    @media (--media--md) {
        --text--base: 1rem;
    }
    @media (--media--sm) {
        --text--base-height: 1.25rem;
    }
    @media (--media--md) {
        --text--base-height: 1.25rem;
    }

    /* headline-size*/

    /* xs */

    @media (--media--sm) {
        --headline--xs: 1.25rem;
    }
    @media (--media--md) {
        --headline--xs: 1.25rem;
    }
    @media (--media--sm) {
        --headline--xs-height: 1;
    }
    @media (--media--md) {
        --headline--xs-height: 1;
    }

    /* sm */

    @media (--media--sm) {
        --headline--sm: 1.5rem;
    }
    @media (--media--md) {
        --headline--sm: 1.5rem;
    }
    @media (--media--sm) {
        --headline--sm-height: 1;
    }
    @media (--media--md) {
        --headline--sm-height: 1;
    }

    /* md */

    @media (--media--sm) {
        --headline--md: 1.75rem;
    }
    @media (--media--md) {
        --headline--md: 2rem;
    }
    @media (--media--sm) {
        --headline--md-height: 1;
    }
    @media (--media--md) {
        --headline--md-height: 1;
    }

    /* lg */

    @media (--media--sm) {
        --headline--lg: 1.9rem;
    }
    @media (--media--md) {
        --headline--lg: 4rem;
    }
    @media (--media--sm) {
        --headline--lg-height: 1;
    }
    @media (--media--md) {
        --headline--lg-height: 1;
    }

    /* xl */

    @media (--media--sm) {
        --headline--xl: 3rem;
    }
    @media (--media--md) {
        --headline--xl: 6rem;
    }
    @media (--media--sm) {
        --headline--xl-height: 0.85;
    }
    @media (--media--md) {
        --headline--xl-height: 0.85;
    }
}

/* breakpoints with custom media, because css variables is not supportet in media queries */
@custom-media --media--sm (min-width: 300px);
@custom-media --media--md (min-width: 900px);
@custom-media --media--lg (min-width: 1200px);
@custom-media --media--xl (min-width: 2000px);
