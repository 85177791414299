.image-tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: var(--color--dark);
    border-radius: 25px;

    .media-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: var(--spacing--xs);

        img {
            width: 100%;
            height: auto;
            border-radius: 25px;
        }
    }

    .text-wrapper {
        height: fit-content;
        text-align: center;

        h3 {
            font-weight: 700;
        }
    }
}
