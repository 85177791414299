header.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
    background: white;
    padding: var(--spacing--xs) 0;
    touch-action: manipulation;

    @media (--media--md) {
        background: none;
    }

    .logo {
        display: inline-block;
        z-index: 10000;

        svg {
            display: block;
            height: 50px;

            @media (--media--md) {
                height: 80px;
            }

            .path1 {
                transition: all 0.15s var(--easing--in-out);
                fill: var(--color--font-base);

                @media (--media--md) {
                    fill: white;
                }
            }
        }
    }

    &.fade-in {
        background: white;

        @media (--media--md) {
            background: none;
        }

        .logo {
            svg {
                .path1 {
                    transition: all 0.15s var(--easing--in-out);
                    fill: var(--color--font-base);
                }
            }
        }
    }

    &.active {
        background: none;
        .logo {
            svg {
                .path1 {
                    fill: white;
                }
            }
        }
    }

    .background {
        display: none;
        min-width: 100vw;
        min-height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        /* background: rgba(255, 255, 255, 0.7); */
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(11px);
    }

    &.active {
        .background {
            display: block;
        }

        .logo {
            display: inline-block;
        }
    }

    div.header--inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}
