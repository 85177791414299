div.accordion--item {
    display: block;
    width: 100%;
    margin: 0.5rem 0;
    margin-left: 0;

    &:first-child {
        margin-top: 2rem;
    }
    &:last-child {
        margin-bottom: 0;
    }

    button {
        width: 100%;
        position: relative;
        font-size: var(--text--base);
        line-height: var(--text--base-height);
        font-weight: 700;
        color: var(--color--font-base);
        padding: var(--spacing--xs) 2.5rem;
        padding-right: var(--spacing--lg);
        padding-left: 0;
        text-align: left;

        div.icon {
            width: 0.75rem;
            height: 0.75rem;
            position: absolute;
            top: 50%;
            right: 1.25rem;
            transform: translateY(-50%);

            span {
                display: block;
                width: 100%;
                height: 3px;
                position: absolute;
                top: 50%;
                background: var(--color--font-base);
                border-radius: 10px;
                transition: all 0.35s var(--easing--in-out);

                &:first-child {
                    transform: translateY(-50%) rotate(-90deg);
                }

                &:last-child {
                    transform: translateY(-50%);
                }
            }
        }
    }

    div.container {
        display: grid;
        grid-template-rows: 0fr;
        margin-top: -4px;
        border-bottom: 2px solid var(--color--font-base);
        transition: grid-template-rows 0.5s var(--easing--in-out);

        div.container--inner {
            overflow: hidden;
        }
    }

    &.active {
        button {
            color: var(--color--primary);

            div.icon {
                span:first-child {
                    transform: translateY(-50%) rotate(0deg);
                }

                span {
                    background: var(--color--primary);
                }
            }
        }

        div.container {
            grid-template-rows: 1fr;
            padding: 1rem;
            padding-left: 0;
            margin-top: -2px;
        }
    }
}
