nav.navigation--footer {
    ul.navigation--list {
        li.navigation--listitem {
            list-style-type: none;
            display: inline;
            padding: 0.25rem;
            padding-left: 0;

            a {
                text-decoration: underline;
                color: var(--color--dark);

                @media (hover: hover) {
                    &:hover {
                        color: var(--color--primary) !important;
                    }
                }
            }
        }
    }
}
