/* bold */
@font-face {
    font-family: "Ropa";
    src: url("../fonts/ropa-extrabold.woff2") format("woff2"),
        url("../fonts/ropa-extrabold.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

/* regular */
@font-face {
    font-family: "Ropa";
    src: url("../fonts/ropa-regular.woff2") format("woff2"),
        url("../fonts/ropa-regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
