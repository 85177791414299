div.accordion {
    display: block;
    width: 100%;
    min-height: 60px;
}

#join {
    div.accordion {
        order: 0;
        margin-bottom: var(--spacing--md);

        @media (--media--md) {
            order: 1;
            margin-bottom: 0;
        }
    }

    form {
        order: 1;

        @media (--media--md) {
            order: 0;
            margin-bottom: 0;
        }
    }
}
