.textmedia {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    @media (--media--lg) {
        grid-template-columns: 3fr repeat(2, 1fr) 3fr;
        grid-template-rows: 1fr;
    }

    &.text-right {
        .text-content {
            order: 2;
            grid-area: 2 / 1 / 3 / 2;

            @media (--media--lg) {
                grid-area: 1 / 4 / 2 / 5;
            }
        }

        .media-content {
            order: 1;
            grid-area: 1 / 1 / 2 / 2;

            @media (--media--lg) {
                grid-area: 1 / 1 / 2 / 3;
            }
        }
    }

    .text-content {
        align-self: center;
        grid-area: 2 / 1 / 3 / 2;

        @media (--media--lg) {
            grid-area: 1 / 1 / 2 / 2;
        }

        ol {
            white-space: nowrap;
            overflow: visible;

            column-count: 1;

            @media (--media--md) {
                column-count: 2;
            }
        }

        .icons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 70%;

            svg {
                width: 60%;
            }
        }
    }

    .media-content {
        position: relative;
        margin-bottom: var(--spacing--sm);
        grid-area: 1 / 1 / 2 / 2;
        align-self: center;

        @media (--media--lg) {
            margin-bottom: 0;
            grid-area: 1 / 3 / 2 / 5;
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: -6px;
            object-fit: contain;
            border-radius: 25px 25px 0 0;

            @media (--media--lg) {
                border-radius: 25px;
            }
        }

        .card {
            width: 100%;
            background-color: var(--color--primary);
            padding: var(--spacing--sm);
            border-radius: 0 0 25px 25px;

            @media (--media--lg) {
                width: fit-content;
                position: absolute;
                bottom: -30px;
                right: -30px;
                border-radius: 25px;
            }
        }
    }
}
