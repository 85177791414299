figcaption.figcaption {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0 1.25rem;
    color: var(--color--secondary);

    @media (--media--md) {
        padding: 0 2.5rem;
    }
}
