.hamburger-icon {
    flex-direction: column;
    pointer-events: none;
    transform: scale(1);
    transition: transform 0.35s var(--easing--in-out);

    @media (hover: hover) {
        &:hover {
            transform: scale(1.1);
        }
    }

    span {
        display: block;
        width: 15px;
        height: 3px;
        background-color: var(--color--font-base);
        margin: 2px auto;
        border-radius: 10px;
    }

    &.active {
        span {
            &:first-child {
                transform: translateY(5px) rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:last-child {
                width: 100%;
                transform: translateY(-5px) rotate(-45deg);
            }
        }
    }
}
