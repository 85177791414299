nav.header-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 10000;
    grid-column: large-end;

    .blur {
        display: block;
        opacity: 0;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -10;
        background: rgba(255, 255, 255, 0.8);
        transition: all 0.25s var(--easing--in-out);
        backdrop-filter: blur(20px);

        &.active {
            opacity: 1;
            transition: all 0.25s var(--easing--in-out);
        }
    }

    .circlebutton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: var(--color--primary);
        margin-left: 25px;
        border-radius: 100px;
        cursor: pointer;
        transform: scale(1);
        transition: all 0.35s var(--easing--in-out);

        @media (hover: hover) {
            &:hover {
                box-shadow: var(--shadow--sm);
                transform: scale(1.1);
                transition: all 0.25s var(--easing--in-out);
            }
        }

        &.fc--primary {
            background-color: var(--color--primary);
        }

        &.fc--third {
            background-color: var(--color--third);
        }

        &.menu {
            margin-top: 0px;

            @media (--media--md) {
                margin-top: 15px;
            }
        }
    }

    .nav-list {
        display: none;
        flex-direction: column;
        justify-content: flex-end;
        right: var(--spacing--sm);
        margin-top: (calc(50px + var(--spacing--sm)));
        grid-column: large-end;

        @media (--media--sm) {
            right: 0;
        }

        &.active {
            display: inline;
        }

        li {
            list-style-type: none;

            &:hover {
                .item-label {
                    margin-right: 0.75rem;
                    transition: all 0.35s var(--easing--in-out);
                }
                .circlebutton {
                    box-shadow: var(--shadow--sm);
                    transform: scale(1.1);
                    transition: all 0.35s var(--easing--in-out);
                }
            }

            &.current {
                pointer-events: none;

                .circlebutton {
                    transform: scale(1.1);
                    transition: all 0.25s var(--easing--in-out);
                }
                .item-label {
                    font-weight: 900;
                    margin-right: 0.75rem;
                }
            }

            a {
                display: flex;
                flex-direction: row;
                justify-content: right;
                align-items: center;
                width: auto;
                font-size: 25px;
                font-weight: 400;
                color: var(--color--dark);
                margin: var(--spacing--sm) 0;
                border-radius: 100px;

                .item-label {
                    font-weight: 400;
                    transition: all 0.25s var(--easing--in-out);

                    &.current {
                        font-weight: 400;
                    }
                }

                .item-icon {
                    img {
                        border-radius: 100px;
                    }
                }
            }
        }
    }
}
