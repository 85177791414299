form {
    display: block;
    width: 107.5%;
    margin-top: 0.75rem;
    margin-left: -12.5px;
    grid-column: mediumlarge-start / center-start;

    fieldset {
        border: none;

        .clearfix {
            display: inline-block;
            width: 99%;
            position: relative;
            margin: 0.5rem 0rem;

            @media (hover: hover) {
                &:hover {
                    .add-on {
                        &:before {
                            background: var(--color--primary);
                        }
                    }
                }
            }

            .input {
                input {
                    width: 100%;
                    color: var(--color--font-base);
                    background: #ececec;
                    padding: 0.5rem 1.25rem;
                    border: none;
                    border: 2px solid #ececec;
                    border-radius: 100px;

                    &::placeholder {
                        opacity: 0.4;
                    }

                    &:focus-visible {
                        outline: none;
                        border: 2px solid var(--color--primary);
                    }
                }
            }

            label {
                font-size: 0.75rem;

                &[for*="privacy-policy"] {
                    display: block;
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    color: var(--color--font-base);
                    padding-top: 0;
                    padding-left: 40px;
                    margin: var(--spacing--xs) 0;
                }

                &.add-on {
                    display: block;
                    position: absolute;
                    top: 40px;
                    left: 0;
                    padding-top: 0;
                    transform: translateY(-100%);

                    input {
                        display: none;
                    }

                    &:before {
                        content: url("");
                        display: block;
                        width: 25px;
                        height: 25px;
                        z-index: 200;
                        z-index: 400;
                        border: var(--color--font-base) 1px solid;
                        border-radius: 100px;
                    }

                    &.checked {
                        &:before {
                            background: var(--color--primary);
                        }

                        &:after {
                            content: url("/_Resources/Static/Packages/PX.Site/Images/checkmark.svg");
                            display: block;
                            min-width: 20px;
                            min-height: 20px;
                            position: absolute;
                            top: calc(50% + 0.1rem);
                            left: 13px;
                            filter: invert(48%) sepia(0%) saturate(1004%)
                                hue-rotate(135deg) brightness(90%) contrast(90%);
                            transform: translate(-50%, -50%);
                            transition: all 0.15s var(--easing--in-out);
                        }
                    }
                }
            }

            &.w50 {
                display: inline-block;
                width: 100%;
                height: auto;

                @media (--media--sm) {
                    width: 49%;
                }
            }

            &.w25 {
                display: inline-block;
                width: 28%;
                height: auto;
            }

            &.w33 {
                display: inline-block;
                width: 98%;
                height: auto;
            }

            &.w75 {
                display: inline-block;
                width: 70%;
                height: auto;
            }

            ul.inputs-list {
                display: inline-block;
                width: 100%;
                float: left;

                li {
                    list-style: none;
                    display: inline;
                    width: 100%;

                    &:last-child {
                        margin-right: 0;
                    }

                    label {
                        display: block;
                        position: relative;
                        margin-right: 5px;
                        float: left;

                        &.active {
                            span {
                                color: var(--color--font-base);
                                transition: all 0.15s var(--easing--in-out);

                                &:before {
                                    background: var(--color--primary);
                                    border: 1px solid var(--color--primary);
                                    border: 1px solid var(--color--font-base);
                                    transition: all 0.15s var(--easing--in-out);
                                }

                                &:after {
                                    opacity: 100;
                                    filter: invert(48%) sepia(0%)
                                        saturate(1004%) hue-rotate(135deg)
                                        brightness(90%) contrast(90%);
                                    transition: all 0.15s var(--easing--in-out);
                                }
                            }
                        }

                        input {
                            display: none;
                        }

                        span {
                            display: flex;
                            position: relative;
                            font-size: 1rem;
                            font-weight: 400;
                            color: var(--color--font-base);
                            background: #ececec;
                            padding: 0.5rem 0.5rem 0.5rem 2rem;
                            float: left;
                            border: 2px solid #ececec;
                            border-radius: 100px;
                            cursor: pointer;
                            transition: all 0.15s var(--easing--in-out);
                            text-align: center;

                            @media (hover: hover) {
                                &:hover {
                                    &:before {
                                        background: var(--color--primary);
                                    }
                                }
                            }

                            &:before {
                                content: "";
                                display: block;
                                min-width: 25px;
                                min-height: 25px;
                                position: absolute;
                                top: 50%;
                                left: 1rem;
                                background: none;
                                margin-right: 0.5rem;
                                border: 1px solid var(--color--font-base);
                                border-radius: 100px;
                                transform: translate(-50%, -50%);
                                transition: all 0.15s var(--easing--in-out);
                            }

                            &:after {
                                content: url("/_Resources/Static/Packages/PX.Site/Images/checkmark.svg");
                                display: block;
                                opacity: 0;
                                min-width: 20px;
                                min-height: 20px;
                                position: absolute;
                                top: calc(50% + 0.15rem);
                                left: 1rem;
                                transform: translate(-50%, -50%);
                                transition: all 0.15s var(--easing--in-out);
                            }
                        }
                    }
                }
            }
        }
    }

    .form-navigation {
        display: block;
        float: left;

        ul {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: 0 18px;
            padding-top: 2em;
            float: left;

            li {
                list-style: none;
                display: inline;

                button {
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    letter-spacing: 0.5px;
                    color: var(--color--light);
                    background-color: var(--color--font-base);
                    padding: 0.5rem 2rem;
                    border-radius: 30px;
                    transition: all 0.15s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            background-color: #b6b6b6;
                            transition: all 0.15s var(--easing--in-out);
                        }
                    }
                }
            }
        }
    }
}
