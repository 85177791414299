.textmaps {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    @media (--media--md) {
        grid-template-columns: 3fr repeat(2, 1fr) 3fr;
        grid-template-rows: 1fr;
    }

    .text-content-maps {
        grid-area: 1 / 1 / 2 / 2;
        justify-self: center;
        align-self: center;

        @media (--media--md) {
            grid-area: 1 / 1 / 2 / 2;
        }
    }

    .media-content {
        display: flex;
        flex-direction: column;
        position: relative;
        grid-area: 2 / 1 / 3 / 2;

        @media (--media--md) {
            grid-area: 1 / 3 / 2 / 5;
        }

        .maps {
            overflow: hidden;
            border-radius: 25px;
        }

        .adress {
            width: fit-content;
            background-color: var(--color--primary);
            padding: var(--spacing--sm);
            border-radius: 25px;
            align-self: center;

            @media (--media--md) {
                position: absolute;
                bottom: -30px;
                right: -30px;
                border-radius: 25px;
            }
        }
    }
}
