.tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color--primary);
    padding: var(--spacing--sm);
    border-radius: 25px;

    .event {
        h3 {
            font-size: var(--headline--xs);
        }
    }

    .date {
        display: flex;
        position: relative;
        gap: 0;

        &::before {
            content: url("/_Resources/Static/Packages/PX.Site/Images/calendar.svg");
            display: inline-block;
            max-width: 13px;
            max-height: 18px;
            margin-right: 10px;
            margin-bottom: 10px;
            transform: scale(1.2);
        }
    }
}
