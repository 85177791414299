.icon-tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: var(--color--light);
    padding: var(--spacing--sm);
    margin-top: 75px;
    border-radius: 25px;
    box-shadow: var(--shadow--sm);

    .svg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        position: absolute;
        top: 0;
        left: 50%;
        background: var(--color--primary);
        border-radius: 2000px;
        transform: translate(-50%, -50%);

        svg {
            width: 75%;
        }
    }

    .text-wrapper {
        height: auto;
        padding-top: calc(var(--spacing--xs) + 75px);

        h3 {
            font-weight: 700;
        }
    }

    .event {
        h3 {
            font-size: var(--headline--xs);
            line-height: var(--headline--lg-height);
        }
    }

    .description {
        font-size: 18px;
        line-height: var(--paragraph--lg-height);
    }

    .date {
        display: flex;
        position: relative;
        border-top: 0.1px solid var(--color--font-base);
        gap: 0;

        &::before {
            content: url("/_Resources/Static/Packages/PX.Site/Images/calendar.svg");
            display: inline-block;
            max-width: 13px;
            max-height: 18px;
            margin-right: 10px;
            margin-bottom: 10px;
            transform: scale(1.2);
        }
    }
}
