div.headline {
    display: block;
    width: 100%;
    font-family: var(--font--base-family);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        display: block;
        width: 100%;
        font-weight: 400;
        hyphens: auto;

        strong {
            font-weight: 900;
        }
    }

    u {
        display: inline-block;
        text-decoration: none;
        color: var(--color--font-base);
        background: var(--color--primary);
        padding: 0.25rem 0.75rem;
        border-radius: 100px;
    }
}
