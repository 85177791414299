.tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color--primary);
    padding: var(--spacing--sm);
    border-radius: 25px;

    &.youth {
        background: var(--color--third);
    }

    &.past {
        background: #d4d4d4;
    }

    .event {
        h3 {
            font-size: var(--headline--xs);
            line-height: var(--headline--lg-height);
        }
    }

    .description {
        font-size: 18px;
        line-height: var(--paragraph--lg-height);
    }

    .date {
        display: flex;
        position: relative;
        border-top: 0.1px solid var(--color--font-base);
        gap: 0;

        &::before {
            content: url("/_Resources/Static/Packages/PX.Site/Images/calendar.svg");
            display: inline-block;
            max-width: 13px;
            max-height: 18px;
            margin-right: 10px;
            margin-bottom: 10px;
            transform: scale(1.2);
        }
    }
}
