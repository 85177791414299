div.pre-footer {
    height: 100%;
    background: #d4d4d4;
    padding: var(--spacing--sm) 0;

    #mc_embed_signup {
        width: 100%;
        font-family: Ropa;
        background: none;
        clear: center;

        #mc-embedded-subscribe-form {
            max-width: 100%;
            margin: 0;

            #mc_embed_signup_scroll {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: var(--spacing--sm) 0;
                gap: var(--spacing--sm);

                @media (--media--md) {
                    margin: var(--spacing--sm);
                }
                .mc-field-group {
                    .helper_text {
                        display: none !important;
                    }

                    input {
                        width: 50%;
                        color: var(--color--font-base);
                        background: #ececec;
                        padding: 0.75rem 1.25rem;
                        border: none;
                        border: 2px solid #ececec;
                        border-radius: 100px;

                        &::placeholder {
                            opacity: 0.4;
                        }

                        &:focus-visible {
                            outline: none;
                            border: 2px solid var(--color--primary);
                        }
                    }
                }
                h2 {
                    font-size: var(--headline--md);
                    color: var(--color--font-base);
                    text-align: center;
                }

                .indicates-required {
                    display: none;
                }

                .mc-field-group {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .helper_text {
                        display: none !important;
                    }

                    input {
                        width: 80%;
                        font-size: var(--text--base);
                        color: var(--color--font-base);
                        background: #ececec;
                        padding: 0.75rem 1.25rem;
                        border: none;
                        border: 2px solid #ececec;
                        border-radius: 100px;

                        &::placeholder {
                            opacity: 0.4;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 13px;
                        }

                        &:focus-visible {
                            outline: none;
                            border: 2px solid var(--color--primary);
                        }
                    }
                }

                .mce_inline_error {
                    color: red;
                    background: none;
                }

                .optionalParent {
                    margin-top: -20px;

                    .clear {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .button {
                            display: inline-block;
                            min-width: 160px;
                            height: 45px;
                            font-size: var(--text--base);
                            line-height: var(--text--base-height);
                            letter-spacing: 0.5px;
                            color: var(--color--font-base);
                            background-color: var(--color--primary);
                            padding: 0.5rem 2rem;
                            border-radius: 30px;
                            transition: all 0.15s var(--easing--in-out);

                            @media (hover: hover) {
                                &:hover {
                                    background-color: #f3ef7d;
                                    transition: all 0.15s var(--easing--in-out);
                                }
                            }
                        }
                    }
                }

                img {
                    display: none;
                }

                label {
                    display: none;
                }
            }
        }
    }
}

footer.footer {
    color: white;
    background: var(--color--font-base);
    padding: var(--spacing--md) 0;

    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: fit-content(100px);

        @media (--media--md) {
            grid-template-columns: 2fr repeat(2, 1.5fr);
            grid-auto-rows: fit-content(100px);
        }

        h4 {
            font-size: var(--headline--xs);
            margin-bottom: var(--spacing--sm);
        }

        .adress {
            display: flex;
            flex-direction: column;
            padding: var(--spacing--sm) 0;
            border-bottom: 1px solid rgb(255 255 255 / 20%);
            grid-area: auto / auto / auto / auto;

            @media (--media--md) {
                padding: 0;
                border-bottom: none;
                grid-area: auto / auto / auto / auto;
            }

            a {
                width: fit-content;
                text-decoration: underline;
                color: var(--color--dark);
                transition: color 0.15s var(--easing--in-out);

                @media (hover: hover) {
                    &:hover {
                        color: var(--color--primary) !important;
                    }
                }
            }

            .sm-icons {
                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: var(--spacing--xs);

                    @media (hover: hover) {
                        &:hover {
                            path {
                                fill: var(--color--primary);
                            }
                        }
                    }

                    path {
                        transition: fill 0.15s var(--easing--in-out);
                        fill: white;
                    }
                }
            }
        }

        .downloads {
            padding: var(--spacing--sm) 0;
            border-bottom: 1px solid rgb(255 255 255 / 20%);
            grid-area: auto / auto / auto / auto;

            @media (--media--md) {
                padding: 0;
                border-bottom: none;
                grid-area: auto / auto / auto / auto;
            }

            .navigation--list {
                display: flex;
                flex-direction: column;
            }
        }

        .legal {
            padding: var(--spacing--sm) 0;
            grid-area: auto / auto / auto / auto;

            @media (--media--md) {
                padding: 0;
                grid-area: auto / auto / auto / auto;
            }

            .navigation--list {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
