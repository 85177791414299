.history {
    position: relative;

    svg.arrow-tip {
        opacity: 0.5;
        max-width: 1.5rem;
        position: absolute;
        top: -1.6rem;
        left: 50%;
        transform: translateX(-50%) rotate(-90deg);

        path.st3 {
            fill: var(--color--primary) !important;
        }
    }

    span {
        display: block;
        opacity: 0.5;
        min-width: 0.6rem;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        background: none;
        border-left: 0.3rem dotted var(--color--primary);
        transform: translateX(calc(-50% + 0.15rem));
        grid-area: 1 / 4 / 2 / 5;
    }

    .history-item {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        position: relative;
        margin: var(--spacing--xl) 0;

        @media (--media--md) {
            display: grid;
            grid-template-columns: 5fr 1fr 1fr repeat(2, 0.5fr) 1fr 1fr 5fr;
            grid-template-rows: 1fr;
            position: relative;
            margin: 0;
        }

        .date {
            width: fit-content;
            position: absolute;
            top: -4rem;
            left: 50%;
            z-index: 100;
            z-index: 1;
            font-size: 2.5rem;
            font-weight: 900;
            transform: translate(-50%, calc(-50% + 0.1rem));

            @media (--media--md) {
                top: 50%;
                left: 50%;
            }

            &:before {
                content: "";
                width: 3.5rem;
                height: 3.5rem;
                position: absolute;
                top: -100%;
                left: 50%;
                z-index: -1;
                background: var(--color--primary);
                border-radius: 5rem;
                transform: translateX(-50%);
                align-self: center;
            }
        }

        &.left {
            .card {
                grid-area: 1 / 1 / 2 / 1;
            }

            svg {
                display: none;
                height: 50px;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(100%, -50%);

                @media (--media--md) {
                    display: block;
                }

                polygon {
                    fill: white;
                }
            }
        }

        &.right {
            .card {
                grid-area: 1 / 8 / 2 / 9;
            }

            svg {
                display: none;
                height: 50px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-100%, -50%) rotate(180deg);

                @media (--media--md) {
                    display: block;
                }

                polygon {
                    fill: white;
                }
            }
        }

        .card {
            position: relative;
            background: white;
            padding: var(--spacing--sm);
            border-radius: 25px;

            .headline {
            }
            .text {
            }
        }
    }
}
