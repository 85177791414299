.flex--3 {
    .person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--color--light);

        @media (hover: hover) {
            &:hover {
                .media-content {
                    img {
                        width: 120%;
                        bottom: 0;
                        transition: all 0.35s var(--easing--in-out);
                    }
                }

                .text-content {
                    .name {
                        opacity: 0;
                        transition: all 0.35s var(--easing--in-out);
                    }

                    .position {
                        opacity: 0;
                        transition: all 0.35s var(--easing--in-out);
                    }

                    .description {
                        opacity: 1;
                        transition: all 0.35s var(--easing--in-out);
                    }
                }
            }
        }

        .media-content {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(10%);

            .container {
                display: flex;
                align-items: flex-end;
                width: 300px;
                height: 350px;
                position: relative;
                background: none;
                overflow: hidden;
                border-radius: 0 0 200px 200px;

                span {
                    display: block;
                    width: 100%;
                    position: absolute;
                    background: var(--color--primary);
                    border-radius: 200px 200px 0 0;
                    aspect-ratio: 1 / 1;
                }

                img {
                    display: block;
                    width: 105%;
                    height: auto;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    object-fit: contain;
                    transform: translateX(-50%);
                    transition: all 0.35s ease;
                }
            }
        }

        .text-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 5;
            background: var(--color--font-base);
            padding: var(--spacing--sm);
            border-radius: 25px;

            .name {
                opacity: 1;
                transition: all 0.35s var(--easing--in-out);

                h3 {
                    font-weight: 900;
                }
            }

            .position {
                opacity: 1;
                height: 100%;
                font-size: 18px;
                transition: all 0.35s var(--easing--in-out);
            }

            .mail {
                text-decoration: underline;
                color: var(--color--primary);
                transition: all 0.35s var(--easing--in-out);
            }

            .description {
                opacity: 0;
                position: absolute;
                top: 65px;
                left: 50%;
                padding: var(--spacing--sm);
                transform: translate(-50%, -50%);
                transition: all 0.35s var(--easing--in-out);
            }
        }
    }
}

.flex--4 {
    .person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--color--light);

        @media (hover: hover) {
            &:hover {
                .media-content {
                    img {
                        width: 175%;
                        top: -50px;
                        transition: all 0.35s var(--easing--in-out);
                    }
                }

                .text-content {
                    .name {
                        opacity: 0;
                        transition: all 0.35s var(--easing--in-out);
                    }

                    .position {
                        opacity: 0;
                        transition: all 0.35s var(--easing--in-out);
                    }

                    .description {
                        opacity: 1;
                        transition: all 0.35s var(--easing--in-out);
                    }
                }
            }
        }

        .media-content {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(10%);

            .container {
                display: flex;
                align-items: flex-end;
                width: 225px;
                height: 275px;
                position: relative;
                background: none;
                overflow: hidden;
                border-radius: 0 0 200px 200px;

                span {
                    display: block;
                    width: 100%;
                    position: absolute;
                    background: var(--color--primary);
                    border-radius: 200px 200px 0 0;
                    aspect-ratio: 1 / 1;
                }

                img {
                    display: block;
                    width: 130%;
                    height: auto;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    object-fit: contain;
                    transform: translateX(-50%);
                    transition: all 0.35s ease;
                }
            }
        }

        .text-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 5;
            background: var(--color--font-base);
            padding: var(--spacing--sm);
            border-radius: 25px;

            .name {
                opacity: 1;
                transition: all 0.35s var(--easing--in-out);

                h3 {
                    font-weight: 900;
                }
            }

            .position {
                opacity: 1;
                height: 100%;
                font-size: 18px;
                transition: all 0.35s var(--easing--in-out);
            }

            .mail {
                text-decoration: underline;
                color: var(--color--primary);
                transition: all 0.35s var(--easing--in-out);
            }

            .description {
                opacity: 0;
                position: absolute;
                top: 65px;
                left: 50%;
                padding: var(--spacing--sm);
                transform: translate(-50%, -50%);
                transition: all 0.35s var(--easing--in-out);
            }
        }
    }
}
