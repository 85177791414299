.news-page {
    .news-content {
        grid-column: mediumlarge;
        .news-title {
            width: 100%;
        }

        .date {
            width: 100%;
            font-size: 1.5rem;
            font-weight: 900;
            text-transform: uppercase;
            color: var(--color--primary);
        }

        .article {
            color: var(--color--font--base);
        }
    }

    .media-box {
        height: 60vh;
        overflow: hidden;
        grid-column: space;

        img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            object-fit: cover;
        }
    }
}

.news-section {
    flex-direction: column;
    align-items: center;
    padding: var(--spacing--md) 0;

    .headline {
        grid-column-start: 1;
        font-size: 3.75rem;
        grid-column: mediumlarge;
    }

    .outer {
        grid-column: mediumlarge;

        .swiper {
            height: 100%;
            padding: 50px 10px;
            overflow: hidden;

            @media (--media--md) {
            }

            .news-output {
                .news-item {
                    display: flex;
                    width: 330px;
                    height: auto;
                    margin-right: 30px;
                    border-radius: 25px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
                    cursor: pointer;
                    transition: all 0.35s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            transform: scale(1.015);
                            transition: all 0.35s var(--easing--in-out);

                            a {
                                .media-box {
                                    transform: translateY(-10px);
                                    transition: all 0.35s var(--easing--in-out);
                                }
                            }

                            .button {
                                .arrow {
                                    .line {
                                        width: 50px !important;
                                        transition: all 0.35s
                                            var(--easing--in-out);
                                    }
                                }
                            }
                        }
                    }

                    a {
                        display: flex;
                        flex-direction: column;
                    }

                    .media-box {
                        height: 225px;
                        position: relative;
                        z-index: -1;
                        overflow: hidden;
                        border-radius: 25px 25px 0 0;
                        transition: all 0.35s var(--easing--in-out);

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .content-box {
                        flex-grow: 1;
                        z-index: 1;
                        background-color: var(--color--light);
                        padding: var(--spacing--sm);
                        margin-top: -35px;
                        border-radius: 0 25px 25px 25px;

                        .content-inner {
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            .date {
                                font-weight: 900;
                                color: var(--color--primary);
                            }

                            .text {
                                flex-grow: 1;
                            }

                            .button {
                                display: flex;
                                position: absolute;
                                bottom: 20px;

                                .button-text {
                                    margin-right: 10px;
                                }

                                .arrow {
                                    display: flex;
                                    align-items: center;

                                    .line {
                                        width: 20px;
                                        height: 3px;
                                        background-color: var(--color--primary);
                                        border-radius: 10px;
                                        transition: all 0.35s
                                            var(--easing--in-out);
                                    }
                                    .tip {
                                        display: flex;
                                        align-items: center;
                                        margin-left: -18px;

                                        svg {
                                            height: 15px;
                                            overflow: visible;

                                            path {
                                                stroke: var(--color--primary);
                                                stroke-width: 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .swiper-pagination {
                span.swiper-pagination-bullet {
                    min-width: 40px;
                    max-height: 4px;
                    position: relative;
                    background: var(--color--primary);
                    background: var(--color--primary);
                    padding: 0;
                    border-radius: 2px;
                }

                span.swiper-pagination-bullet-active-prev-prev {
                    opacity: 0.2;
                    background: var(--color--primary);
                    transform: scale(1);
                }

                span.swiper-pagination-bullet-active-prev {
                    opacity: 0.5;
                    background: var(--color--primary);
                    transform: scale(1);
                }

                span.swiper-pagination-bullet-active-next {
                    opacity: 0.5;
                    background: var(--color--primary);
                    transform: scale(1);
                }

                span.swiper-pagination-bullet-active-next-next {
                    opacity: 0.2;
                    background: var(--color--primary);
                    transform: scale(1);
                }
            }
        }
    }

    .prev {
        display: none;
        margin-right: var(--spacing--sm);
        cursor: pointer;
        transform: rotateY(180deg);
        grid-column: large-start / mediumlarge-start;

        @media (--media--md) {
            display: block;
        }

        svg {
            height: 40px;

            path {
                opacity: 1;
                stroke: var(--color--primary);
            }
        }

        &:not(.swiper-button-disabled):hover {
            path {
                opacity: 0.5;
            }
        }

        &.swiper-button-disabled {
            cursor: default;
            path {
                opacity: 0.1;
                stroke: var(--color--font-base);
            }
        }
    }

    .next {
        display: none;
        margin-left: var(--spacing--sm);
        cursor: pointer;
        justify-self: start;
        align-self: center;
        grid-column: large-end / mediumlarge-end;

        @media (--media--md) {
            display: block;
        }

        svg {
            height: 40px;

            path {
                opacity: 1;
                stroke: var(--color--primary);
            }
        }

        &:not(.swiper-button-disabled):hover {
            path {
                opacity: 0.5;
            }
        }

        &.swiper-button-disabled {
            cursor: default;
            path {
                opacity: 0.1;
                stroke: var(--color--font-base);
            }
        }
    }
}
