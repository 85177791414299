div.paragraph {
    display: block;
    width: 100%;
    gap: 2rem;
    columns: 1;

    @media (--media--md) {
        &.md\:columns-2 {
            columns: 2;
        }
    }
    @media (--media--lg) {
        &.lg\:columns-3 {
            columns: 3;
        }
        &.lg\:columns-4 {
            columns: 4;
        }
    }

    li {
        break-inside: avoid;
    }

    ul {
        margin-left: var(--spacing--xs);

        li {
            padding: 0.25rem 0;
        }
    }

    ol {
        list-style-type: none;
        display: block;
        counter-reset: list-number;

        li {
            display: block;
            align-items: center;
            position: relative;
            padding: 0.5rem 0;
            padding-left: 2rem;
            counter-increment: list-number;

            &::before {
                content: counter(list-number);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                position: absolute;
                top: 9px;
                left: 0;
                font-weight: 900;
                color: var(--color--font-base);
                background-color: var(--color--primary);
                border-radius: 50px;
                aspect-ratio: 1 / 1;
            }
        }
    }

    a {
        font-weight: 900;
        text-decoration: underline;
    }
    /* FOR BACKEND */
    .ck-content {
        .table {
            display: inline !important;
        }
    }
    /* FOR BACKEND */

    table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;

        th,
        td {
            width: 20%;
            font-weight: 900;
            padding: 0.5rem 1rem;
            border: 1px solid var(--color--font-base);
        }

        th {
            font-weight: 400;
            color: white;
            background-color: rgba(0, 0, 0, 0.4);
        }

        td {
            background-color: var(--color--primary);

            &.empty {
                background-color: transparent;
                opcaity: 0;
            }
        }
    }
}
