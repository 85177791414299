div.group {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing--xs);

    .tile,
    .icon-tile,
    .image-tile,
    .fact,
    .person,
    .gallery-image,
    .accordion,
    form {
        --el--row-count: 1;
        --gap-count: calc(var(--el--row-count) - 1);

        width: calc(
            100% / var(--el--row-count) -
                (var(--gap-count) / var(--el--row-count) * var(--spacing--xs))
        );
    }

    &.flex--2 {
        .tile,
        .icon-tile,
        .image-tile,
        .fact,
        .person,
        .gallery-image,
        .accordion,
        form {
            @media (--media--sm) {
                --el--row-count: 1;
            }
            @media (--media--md) {
                --el--row-count: 2;
            }
        }
    }

    &.flex--3 {
        .tile,
        .icon-tile,
        .image-tile,
        .fact,
        .person,
        .gallery-image,
        .accordion,
        form {
            @media (--media--sm) {
                --el--row-count: 1;
            }
            @media (--media--md) {
                --el--row-count: 2;
            }
            @media (--media--lg) {
                --el--row-count: 3;
            }
        }
    }

    &.flex--4 {
        .tile,
        .icon-tile,
        .image-tile,
        .fact,
        .accordion,
        .gallery-image,
        form {
            @media (--media--sm) {
                --el--row-count: 2;
            }
            @media (--media--md) {
                --el--row-count: 3;
            }
            @media (--media--lg) {
                --el--row-count: 4;
            }
        }

        .person {
            @media (--media--sm) {
                --el--row-count: 1;
            }
            @media (--media--md) {
                --el--row-count: 2;
            }
            @media (--media--lg) {
                --el--row-count: 3;
            }
            @media (--media--xl) {
                --el--row-count: 4;
            }
        }
    }

    &.justify--start {
        justify-content: flex-start;
    }

    &.justify--center {
        justify-content: center;
    }

    &.justify--end {
        justify-content: flex-end;
    }
}
