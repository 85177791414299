.fact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: var(--color--light);
    padding: var(--spacing--sm);
    overflow: hidden;
    border-radius: 25px;

    svg {
        opacity: 0.5;
        width: 250px;
        position: absolute;
        bottom: -50px;
        left: 60%;
        .st0 {
            fill: var(--color--primary);
        }
    }

    .number {
        font-size: var(--headline--lg);
        line-height: var(--headline--sm-height);
        font-weight: 600;
        color: var(--color--primary);
    }

    .headline {
    }

    .underline {
        max-width: 60%;
    }
}
