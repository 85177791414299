.hero-banner {
    align-items: center;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 1;
    text-align: right;

    &.text-right {
        .hero-media {
            left: 0;
        }

        .hero-content {
            grid-column: medium-end / large-end;
        }
    }

    .hero-media {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 0;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .gradient {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.35);
        }
    }

    .hero-content {
        position: relative;
        z-index: 1;
        color: var(--color--light);
        grid-column: mediumlarge;
        text-align: left;

        h1 {
            .headline0 {
                display: block;
                font-size: var(--headline--sm);
                line-height: var(--headline--sm-height);
                font-weight: 600 !important;
                text-transform: uppercase !important;
            }

            .headline1 {
                font-size: 4rem;
                line-height: var(--headline--xl-height);
                font-weight: 600 !important;
                text-transform: uppercase !important;

                @media (--media--sm) {
                    font-size: var(--headline--xl);
                }
            }
        }

        .text {
            width: 100%;

            @media (--media--md) {
                width: 35%;
            }
        }
    }
}
