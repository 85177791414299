a.button {
    display: inline-block;
    font-size: var(--text--base);
    line-height: var(--text--base-height);
    letter-spacing: 0.5px;
    padding: 0.5rem 2rem;
    border-radius: 30px;
    transition: all 0.15s var(--easing--in-out);

    &.primary {
        color: var(--color--font-base);
        background-color: var(--color--primary);
        @media (hover: hover) {
            &:hover {
                background-color: #f3ef7d;
                transition: all 0.15s var(--easing--in-out);
            }
        }
    }

    &.secondary {
        color: var(--color--light);
        background-color: var(--color--font-base);
        @media (hover: hover) {
            &:hover {
                background-color: #b6b6b6;
                transition: all 0.15s var(--easing--in-out);
            }
        }
    }
}
